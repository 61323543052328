import React, { useEffect, useState } from 'react';
import { FormikProvider, useFormik, Form } from 'formik';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { Paper, Button, CardContent, CardActions, Card, TextField, Box, Stack,OutlinedInput, ListItemText, FormControl, Checkbox, FormControlLabel, FormHelperText, MenuItem, Select, InputLabel, Autocomplete, Grid, Divider, Typography, TableRow, TableCell, IconButton, TablePagination } from '@mui/material';
import { Post, apiURL, Get, Put ,GetAll} from '../../../utils/apiCalls';
import { LoadingButton } from '@mui/lab';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Base64File } from '../../../utils/Base64File';
import { styled } from '@mui/material/styles';
import LaunchIcon from '@mui/icons-material/Launch';
import { dataURItoBlob } from '../../../utils/Base64File';


const AdminSchema = Yup.object().shape({
   first_name: Yup.string().required('First Name is required'),
   last_name: Yup.string().required('Last Name is required'),
   email: Yup.string().email('Invalid Email').required('Email is required'),
   phone: Yup.string().matches(/^[6-9]\d{9}$/, 'Please Enter valid Phone Number').required('Phone Number is required'),
   role: Yup.number().required('Role is required'),
});

const AdminSchemaEdit = Yup.object().shape({
   first_name: Yup.string().required('First Name is required'),
   last_name: Yup.string().required('Last Name is required'),
   email: Yup.string().email('Invalid Email').required('Email is required'),
   phone: Yup.string().matches(/^[6-9]\d{9}$/, 'Please Enter valid Phone Number').required('Phone Number is required'),
   role: Yup.number().required('Role is required'),

});

const AdminRoleCreate = () => {
   const { id } = useParams()
   const navigate = useNavigate();
   const [roles, setRoles] = useState([]);
   const [agents, setAgents] = useState([]);
   const [checked, setChecked] = useState(false);
   const [upload, setUpload] = useState({ aadhar_upload: '', pan_upload: '' });
   const [projects, setProjects] = useState([]);
   const [loading, setLoading] = useState(true);
    const fetchRolesAndProjects = async () => {
            try {
               const rolesRes = await GetAll(apiURL + '/api/v1/users/admin_roles');
                const projects = await GetAll(apiURL + '/api/v1/projects');
                setRoles(rolesRes.data?.data || []);
               setProjects(projects.data?.data?.map((project) => {
                  return { id: project.id, name: project.name }
               }) || []);
                
                if (id) {
                    const response = await Get(apiURL + '/api/v1/users/get/admin/', id);
                    if (response && response.data && response.data.status) {
                        setData(response.data.data);
                    }

                }

            } catch (error) {
                console.error('Error fetching data:', error);
                setProjects([
                    { id: 1, name: 'Project Alpha' },
                    { id: 2, name: 'Project Beta' },
                    { id: 3, name: 'Project Gamma' },
                    { id: 4, name: 'Project Delta' },
                ]);
            } finally {
                setLoading(false);
            }
        };
   const [data, setData] = useState({
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      role: '',
      project_list: [],
   });

   const getRolesbyID = async (id) => {
      if (id) {
         const response = await Get(apiURL + '/api/v1/users/getRoleByuser/', id)
         if (response && response.data && response.data.status) {
            setRoles(response.data.data)
         }
      }
   }

   // const Roles = async () => {
   //    if (id) {
   //       const responseAgents = await Get(apiURL + '/api/v1/users/agentdropdownget', '')
   //       const responseGet = await Get(apiURL + '/api/v1/users/get/agent/', id)
   //       if (responseGet && responseGet.data) {
   //          setData(responseGet.data.data)
   //          setUpload({ ...responseGet.data.data['doc_id'] })
   //          if (responseGet.data.data['ref_agent_id']) {
   //             if (responseAgents && responseAgents.data && responseAgents.data.status) {
   //                const agent = responseAgents.data.data.find((data) => data.id === responseGet.data.data['ref_agent_id'])
   //                if (agent) {
   //                   getRolesbyID(agent.role)
   //                }
   //                setAgents(responseAgents.data.data)
   //             }

   //          }
   //       }
   //       else {
   //          toast.error('Please Try Again')
   //          navigate('/dashboard/projectadmin')
   //       }
   //    }

   //    const responseAgents = await Get(apiURL + '/api/v1/users/agentdropdownget', '')
   //    if (responseAgents && responseAgents.data && responseAgents.data.status) {
   //       setAgents(responseAgents.data.data)
   //    }
   // }

   useEffect(() => {
      // Roles()
      fetchRolesAndProjects()
   }, [id])

   const formik = useFormik({
      initialValues: {
         ...data
      },
      enableReinitialize: true,
      validationSchema: id ? AdminSchemaEdit : AdminSchema,
      onSubmit: async (values, { setSubmitting }) => {
         if (id) {
            const response = await Put(apiURL + '/api/v1/users/update/admin/', id, { ...values })
            if (response && response.data && response.data.status) {
               toast.success(response.data.message)
               setSubmitting(false)
            //    navigate('/dashboard/adminAgentUser')
            }
            else {
               toast.error(response?.data?.message || 'Please Try Again')
               setSubmitting(false)
            }
         }
         else {
            const response = await Post(apiURL + '/api/v1/users/create/admin', { ...values })
            if (response && response.data && response.data.status) {
               toast.success(response.data.message)
               setSubmitting(false)
               navigate('/dashboard/adminAgentUser')
            }
            else {
               toast.error(response?.data?.message || 'Please Try Again')
               setSubmitting(false)
            }
         }
      }
   });

   const { errors, touched, values, setFieldValue, isSubmitting, handleSubmit, getFieldProps, resetForm, handleBlur } = formik;


   const handleDocUpload = async (id, file) => {
      const data = { file: id === 'aadhar_upload' ? 'AAdhar Card' : 'Pan Card', data: file }
      const response = await Post(apiURL + '/api/v1/users/uploaddoc', { ...data }, navigate)
      if (response && response.data && response.data.status) {
         setUpload((prev) => ({ ...prev, [id]: response?.data?.data?.data }))
         toast.success(`${data.file} Uploaded Successfully`)
         setFieldValue(id, response?.data?.data?.id)
      }
      else {
         toast.error(response?.data?.message || 'Please Try Again')
      }
   }

   const handleFiles = async (e) => {
      const { id, files } = e.target
      if ((files[0]['size'] / 1024) <= 2000) {
         const base64 = await Base64File(files[0])
         const file = { name: files[0]['name'], size: files[0]['size'], base64: base64 }
         handleDocUpload(id, file)
      }
      else {
         toast.error('Please Upload File less than 2 MB')
      }
   }

   const handleChangeCheck = (e) => {
      setFieldValue('check_president', e.target.checked)
      if (e.target.checked)
         setFieldValue('role.id', '1')
      else
         setFieldValue('role.id', '')
   }

   const handleChangeCheckTerms = (e) => {
      setChecked(e.target.checked)
   }
   return (
      <FormikProvider value={formik}>
         <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Card>
               <CardContent>
                  <Typography align='center' variant='h4'>{id ? "EDIT AGENT/PROCUREMENT" : "CREATE AGENT/PROCUREMENT"}</Typography>
                  <Grid container spacing={2}>
                            {/* Text Fields */}
                            {[
                                { label: "First Name", name: "first_name" },
                                { label: "Last Name", name: "last_name" },
                                { label: "Email Address", name: "email" },
                               { label: "Phone Number", name: "phone" },
                            ].map(({ label, name }) => (
                                <Grid item xs={12} md={6} lg={4} key={name}>
                                    <TextField
                                        fullWidth
                                        label={label}
                                        {...getFieldProps(name)}
                                        error={Boolean(touched[name] && errors[name])}
                                        helperText={touched[name] && errors[name]}
                                    />
                                </Grid>
                            ))}

                            {/* Role Dropdown */}
                            <Grid item xs={12} md={6} lg={4}>
                                <FormControl fullWidth error={Boolean(touched.role && errors.role)}>
                                    <InputLabel>Role</InputLabel>
                                    <Select
                                        value={values.role}
                                        onChange={(e) => setFieldValue('role', e.target.value)}
                                        label="Role"
                           >
     
                                        {roles.length > 0 ? (
                                            roles.map((role) => (
                                                <MenuItem key={role.id} value={role.id}>
                                                    {role.designation}
                                                </MenuItem>
                                            ))
                                        ) : (
                                            <MenuItem disabled>No roles available</MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                                {touched.role && errors.role && (
                                    <Typography color="error">{errors.role}</Typography>
                                )}
                            </Grid>

                            {/* Multi-select Project Dropdown */}
                           <Grid item xs={12} md={6} lg={4}>
                               { console.log(values)}
  <FormControl fullWidth error={Boolean(touched.project_list && errors.project_list)}>
    <InputLabel>Projects</InputLabel>
    <Select
      multiple
      value={values.project_list}
      onChange={(e) => setFieldValue('project_list', e.target.value)}
      input={<OutlinedInput label="Projects" />}
      renderValue={(selected) =>
        selected
          .map((id) => {
            const project = projects.find((proj) => proj.id === id);
            return project ? project.name : id;
          })
          .join(', ')
      }
    >
      {projects.length > 0 ? (
        projects.map((project) => (
          <MenuItem key={project.id} value={project.id}>
            <Checkbox checked={values.project_list?.includes(project.id)} />
            <ListItemText primary={project.name} />
          </MenuItem>
        ))
      ) : (
        <MenuItem disabled>No projects available</MenuItem>
      )}
    </Select>
  </FormControl>
  {touched.project_list && errors.project_list && (
    <Typography color="error">{errors.project_list}</Typography>
  )}
</Grid>

                        </Grid>

               </CardContent>
               <Stack ml={'20px'} spacing={1} direction={'row'}>
                  <FormControlLabel
                     value="end"
                     control={<Checkbox checked={checked}
                        onChange={handleChangeCheckTerms} />}
                     label=""
                     labelPlacement="end"
                  />
                  <Typography align='center' justifyContent={'center'} display={'flex'} alignItems={'center'}>I Accept the <a href={require('../../../img/terms and conditions.pdf')} target="_blank" style={{ cursor: 'pointer', marginLeft: '5px' }}>Terms and Conditions</a></Typography>
               </Stack>
               <CardActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Button href="#/dashboard/adminAgentUser" variant='contained' sx={{ backgroundColor: '#3f51b5', '&:hover': { color: '#ffff', backgroundColor: '#3f51b5' } }}>Back</Button>
                  <LoadingButton size="large" type="submit" variant="contained" loading={isSubmitting} disabled={!checked} sx={{ backgroundColor: '#3f51b5', '&:hover': { color: '#ffff', backgroundColor: '#3f51b5' } }}>Save </LoadingButton>
               </CardActions>
            </Card>
         </Form>
      </FormikProvider>
   )
};

export default AdminRoleCreate;

const VisuallyHiddenInput = styled('input')({
   clip: 'rect(0 0 0 0)',
   clipPath: 'inset(50%)',
   height: 1,
   overflow: 'hidden',
   position: 'absolute',
   bottom: 0,
   left: 0,
   whiteSpace: 'nowrap',
   width: 1,
});