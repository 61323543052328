import React, { useMemo, useState } from 'react';
import { Navigate, Outlet, useLocation, useRoutes } from 'react-router-dom';
import { toast } from "react-toastify";
import DashboardLayout from './layouts/dashboard';
import Dashboard from './pages/Dashboard';

import About from './pages/About';
import Blog from './pages/Blog';
import Contact from './pages/Contact';
import Home from './pages/Home1';
import ProjectLogin from './pages/ProjectLogin';
import LayoutImageAdmin from './pages/layout/admin/LayoutImageAdmin';
import Services from './pages/services/Services';

import Layoutvideo from './pages/Layoutvideo';
import ProjectsAgent from './pages/ProjectsAgent';
import AdminAgentEditCreate from './pages/layout/admin/AdminAgentCreate';
import AdminAgentList from './pages/layout/admin/AdminAgentList';
import AdminEditCreate from './pages/layout/admin/AdminEditCreate';
import AdminList from './pages/layout/admin/AdminList';
import AdminProjectList from './pages/layout/admin/AdminProjectList';
import LayoutImageAgent from './pages/layout/agents/LayoutImageAgent';

import LoginPage from './pages/Login/LoginPage';
import AgentEditCreate from './pages/layout/agents/AgentEditCreate';
import AgentList from './pages/layout/agents/AgentList';
import AgentsOffers from './pages/layout/agents/AgentsOffers';
import CustomerOffers from './pages/layout/agents/CustomerOffers';
import ImageTextReplacer from './pages/layout/agents/imgEdit';
import VisitingEdit from './pages/layout/agents/vistingEdit';

import ForgetPassword from './pages/Login/ForgetPassword';
import OtpVerify from './pages/Login/OtpVerify';
import ResetPassword from './pages/Login/ResetPassword';
import Bankdetails from './pages/layout/admin/Bankdetails';
import { default as ChangePasswordAdmin } from './pages/layout/admin/ChangePassword';
import Commission from './pages/layout/admin/Commission';
import CommissionEditCreate from './pages/layout/admin/CommissionEditCreate';
import CommissionView from './pages/layout/admin/CommissionView';
import { default as ProfileAdmin } from './pages/layout/admin/MyProfile';
import OfferEditCreate from './pages/layout/admin/OfferEditCreate';
import Posters from './pages/layout/admin/Posters';
import Quotations from './pages/layout/admin/Quotations';
import VisitingPoster from './pages/layout/admin/VistingPoster';
import Offers from './pages/layout/admin/offers';
import AgentProjectList from './pages/layout/agents/AgentProjectList';
import AgentofferImages from './pages/layout/agents/AgentofferImages';
import { default as ChangePasswordAgent } from './pages/layout/agents/ChangePassword';
import CheckOutDetails from './pages/layout/agents/CheckOut';
import CustomerofferImages from './pages/layout/agents/CustomerofferImage';
import { default as ProfileAgent } from './pages/layout/agents/MyProfile';
import QuotationsEdit from './pages/layout/agents/QuotationsEdit';
import Wallet from './pages/layout/agents/Wallet';
import ProjectEdit from './pages/layout/withoutAuth/ProjectEdit';
import ProjectList from './pages/layout/withoutAuth/ProjectList';
import PaymentSucess from './pages/layout/agents/paymentSucess';
import PaymentDetails from './pages/layout/agents/paymentDetails';
import AdminRoleCreate from './pages/layout/admin/AdminRoleCreate';

function LogoOnlyLayout() {
  return (
    <>
      <Outlet />
    </>
  );
}


export default function Router() {
  const [account, setAccount] = useState({});

  useMemo(() => {
    setAccount(JSON.parse(sessionStorage.getItem('user_details')))
  }, [sessionStorage.getItem('user_details')])

  const children = [
    { path: 'about', element: <About /> },
    { path: 'home', element: <Home /> },
    { path: 'blog', element: <Blog /> },
    { path: 'Contact', element: <Contact /> },
    { path: 'services', element: <Services /> },
    { path: 'project', element: <ProjectLogin /> },
    { path: 'projectAgent', element: <ProjectsAgent /> },
    { path: 'forgetPassword', element: <ForgetPassword /> },
    { path: 'resetpassword', element: <ResetPassword /> },
    { path: 'projectLayout', element: <ProjectList /> },
    { path: 'projectLayout/:id', element: <ProjectEdit /> },
    { path: 'sample_video', element: <Layoutvideo /> },
    { path: 'login', element: <LoginPage /> },
    { path: 'otp-verify', element: <OtpVerify /> },
    { path: '/', element: <Navigate to="/home" replace /> },
    { path: '*', element: <Navigate to="/home" replace /> },
  ]

  const AgentChildren = [
    { path: 'perfectlayout', element: <Dashboard /> },
    { path: 'projectagent', element: <AgentProjectList /> },
    { path: 'agentUser/create', element: <AgentEditCreate /> },
    { path: 'agentUser', element: <AgentList /> },
    { path: 'projectAgent/:id', element: <LayoutImageAgent /> },
    { path: 'checkOutDetails/:id', element: <CheckOutDetails /> },


    { path: 'profile', element: <ProfileAgent /> },
    { path: 'changePassword', element: <ChangePasswordAgent /> },
    { path: 'wallet', element: <Wallet /> },
    { path: 'Customeroffers', element: <CustomerOffers /> },
    { path: 'agentoffers', element: <AgentsOffers /> },
    { path: 'customerofferimages/:id', element: <CustomerofferImages /> },
    { path: 'agentofferimages/:id', element: <AgentofferImages /> },
    { path: 'commission', element: <Commission /> },
    { path: 'commissionview/:id', element: <CommissionView /> },
    { path: 'bankdetails', element: <Bankdetails /> },
    { path: 'imgEdit', element: < Posters /> },
    { path: 'VisitingPoster', element: < VisitingPoster /> },
    { path: 'imagepage/:id', element: < ImageTextReplacer /> },
    { path: 'visiting/:id', element: < VisitingEdit /> },
    { path: 'Quotations', element: < Quotations /> },
    { path: 'QuotationsEdit/:id', element: < QuotationsEdit /> },

    { path: 'paymentsucess/:id', element: < PaymentSucess /> },
    { path: 'paymentdetails', element: < PaymentDetails /> },
  ]

  const AdminChildern = [
    { path: 'perfectlayout', element: <Dashboard /> },
    { path: 'projectadmin', element: <AdminProjectList /> },
    { path: 'projectAdmin/:id', element: <LayoutImageAdmin /> },
    { path: 'adminUser/create', element: <AdminEditCreate /> },
    { path: 'adminUserRole/create', element: <AdminRoleCreate /> },
    { path: 'adminUserRole/:id', element: <AdminRoleCreate /> },
    { path: 'adminUser/:id', element: <AdminEditCreate /> },
    { path: 'adminUser', element: <AdminList /> },
    { path: 'profile', element: <ProfileAdmin /> },
    { path: 'changePassword', element: <ChangePasswordAdmin /> },
    { path: 'adminAgentUser', element: <AdminAgentList /> },
    { path: 'adminAgentUser/create', element: <AdminAgentEditCreate /> },
    { path: 'adminAgentUser/:id', element: <AdminAgentEditCreate /> },
    { path: 'offers/create', element: <OfferEditCreate /> },
    { path: 'offers/:id', element: <OfferEditCreate /> },
    { path: 'offers', element: <Offers /> },
    { path: 'Customeroffers', element: <CustomerOffers /> },
    { path: 'agentoffers', element: <AgentsOffers /> },
    { path: 'customerofferimages/:id', element: <CustomerofferImages /> },
    { path: 'agentofferimages/:id', element: <AgentofferImages /> },
    { path: 'commission', element: <Commission /> },
    { path: 'commission/:id', element: <CommissionEditCreate /> },
    { path: 'commissionview/:id', element: <CommissionView /> },
    { path: 'bankdetails', element: <Bankdetails /> },
    { path: 'imgEdit', element: < Posters /> },
    { path: 'VisitingPoster', element: < VisitingPoster /> },
    { path: 'imagepage/:id', element: < ImageTextReplacer /> },
    { path: 'visiting/:id', element: < VisitingEdit /> },
    { path: 'Quotations', element: < Quotations /> },
    { path: 'QuotationsEdit/:id', element: < QuotationsEdit /> },
    { path: 'paymentdetails', element: < PaymentDetails /> },
  ]


  return useRoutes([
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: children,
    },
    {
      path: '/dashboard',
      element: <RequireAuth>
        <DashboardLayout />
      </RequireAuth>,
      children: account?.user_type === 'agent' ? AgentChildren : AdminChildern,
    },
  ]);
}

function RequireAuth({ children }) {
  let auth = useAuth();
  let location = useLocation();

  if (!auth) {
    toast.error('Please Login to Continue');
    return (
      <Navigate to="/login" state={{ location }} replace />
    );
  }

  return children;
}

function useAuth() {
  return sessionStorage.getItem('token');
}