import { Box, Drawer, IconButton } from '@mui/material';
// material
import { styled } from '@mui/material/styles';
import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
// components
import NavSection from '../NavSection';
import Scrollbar from '../Scrollbar';
// hooks
import useResponsive from '../useResponsive';
//
import {navConfigAdmin, navConfigAgent,navConfigProp} from './NavConfig';
import NavSectionDesktop from '../NavSectionDesktop';
import Logo from './Logo';

const DRAWER_WIDTHOpen = '18%';
const DRAWER_WIDTHClose = '6%';

// ----------------------------------------------------------------------

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar, isOpenSidebarDesktop, onCloseSidebarDesktop }) {
  const { pathname } = useLocation();
  const [account, setAccount] = useState({});
  const isDesktop = useResponsive('up', 'lg');
const permission = JSON.parse(sessionStorage.getItem('user_details'))

  const RootStyle = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('lg')]: {
      flexShrink: 0,
      width: isOpenSidebarDesktop ? DRAWER_WIDTHClose : DRAWER_WIDTHOpen,
    },
  }));

  useMemo(()=> {
    // setAccount(JSON.parse(sessionStorage.getItem('user')))
  }, [])

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <Box sx={{ display: 'inline-flex', backgroundColor:'white', width:'100%' }}>
        <Logo />
      </Box>
   
      {isOpenSidebarDesktop ? <NavSectionDesktop navConfig={ permission?.user_type === 'agent' ? navConfigAgent : permission?.user_type === 'admin' && permission?.role === 'Super Admin' ? navConfigAdmin  : navConfigProp} /> :
      <NavSection navConfig={ permission?.user_type === 'agent' ? navConfigAgent : permission?.user_type === 'admin' && permission?.role === 'Super Admin' ? navConfigAdmin  : navConfigProp}  /> }
      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ display: 'inline-flex', backgroundColor:'white' }}>
        {/* <Logo /> */}
      </Box>
    </Scrollbar>
  );

  return (
    <RootStyle>
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: {
               width: '50%',
              bgcolor: '#495057'},
          }}
        >
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
       open
          variant="persistent"
          PaperProps={{
            sx: {
              width: isOpenSidebarDesktop ? DRAWER_WIDTHClose : DRAWER_WIDTHOpen,
              bgcolor: '#495057;',
              borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}
