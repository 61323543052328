import axios, { AxiosError } from 'axios';
import { toast } from 'react-toastify';

// export var apiURL = "http://localhost:8000"
export var apiURL = "https://apidev.perfectlayout.in"

export async function Post(url, data) {
  try {
    const response = await axios({ method: 'POST', url: url, data: data, headers: { 'Authorization': 'Barer ' + sessionStorage.getItem('token') } })
    return response
  }
  catch (error) {
    console.log(error)
    const err = error
    if (err.response) {
      if (err.response.status < 200 || err.response.status >= 300) {
        console.log(err.response.status)

        if (err.response.status === 403) {
          sessionStorage.removeItem('token');
          sessionStorage.removeItem('user_details');
          console.log(error)
        }
        else if (err.response.status === 413) {

          console.log(err)
          toast.error('File Upload Too large Plase Try Again')
        }
        else {
          console.log(error)
        }
      }
    }
    else {
      console.log(error)
    }
    return null
  }
};

export async function Get(url, id) {
  try {
    const response = await axios({ method: 'GET', url: url + id, headers: { 'Authorization': 'Barer ' + sessionStorage.getItem('token') } })
    return response
  }
  catch (error) {
    console.log(error)
    const err = error
    console.log(err)
    if (err.response) {
      if (err.response.status < 200 || err.response.status >= 300) {
        console.log(err.response.status)
        if (err.response.status === 403) {
          sessionStorage.removeItem('token');
          sessionStorage.removeItem('user_details');
          console.log(error)
        }
        else {
          console.log(error)
        }
      }
    }
    else {
      console.log(error)
    }
    return null
  }
};

export async function GetAll(url) {

  try {
    const response = await axios({ method: 'GET', url: url, headers: { 'Authorization': 'Barer ' + sessionStorage.getItem('token') } })
    return response
  }
  catch (error) {
    console.log(error)
    const err = error
    if (err.response) {
      console.log(err.response.status)
      if (err.response.status < 200 || err.response.status >= 300) {
        if (err.response.status === 403) {
          sessionStorage.removeItem('token');
          sessionStorage.removeItem('user_details');
          console.log(error)
        }
        else {
          console.log(error)
        }
      }
    }
    else {
      console.log(error)
    }
    return null
  }
};

export async function Put(url, id, data) {
  try {
    const response = await axios({ method: 'put', url: url + id, data: data, headers: { 'Authorization': 'Barer ' + sessionStorage.getItem('token') } })
    return response
  }
  catch (error) {
    console.log(error)
    const err = error
    if (err.response) {
      console.log(err.response.status)
      if (err.response.status < 200 || err.response.status >= 300) {
        if (err.response.status === 403) {
          sessionStorage.removeItem('token');
          sessionStorage.removeItem('user_details');
          console.log(error)
        }
        else {
          console.log(error)
        }
      }
    }
    else {
      console.log(error)
    }
    return null
  }
};

export async function Delete(url, id) {

  try {
    const response = await axios({ method: 'delete', url: url + id, headers: { 'Authorization': 'Barer ' + sessionStorage.getItem('token') } })
    return response
  }
  catch (error) {
    console.log(error)
    const err = error
    if (err.response) {
      console.log(err.response.status)
      if (err.response.status < 200 || err.response.status >= 300) {
        if (err.response.status === 403) {
          sessionStorage.removeItem('token');
          sessionStorage.removeItem('user_details');
          console.log(error)
        }
        else {
          console.log(error)
        }
      }
    }
    else {
      console.log(error)
    }
    return null
  }
};