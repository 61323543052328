import React, { useRef, useState, useEffect } from "react";
import { saveAs } from "file-saver";
import imgEdit from '../../../img/agentposter.jpg'
import { GetAll, apiURL, Put } from "../../../utils/apiCalls";
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { FormikProvider, useFormik, Form } from "formik";
import {
  Button,
  CardContent,
  CardActions,
  Card,
  TextField,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,Box,Autocomplete
} from "@mui/material";
import {

    Stack,

  } from "@mui/material";

const ImageTextReplacer = () => {
  const navigate = useNavigate();  
  const { id } = useParams();

  const canvasRef = useRef(null);
  const [newText, setNewText] = useState();
  const getposter = async (e) => {
    
    const response = await GetAll(apiURL + `/api/v1/posters/byid/${id}`);
    if (response && response.data && response.data.status) {
      
      setImagedata(response.data.data);
      console.log(response.data.data);
      return response.data.data
    }
    return {}
  };
  const [imagedata, setImagedata] = useState({})
  const [isImg, setImg] = useState(false)

  const [isquote,setQuote] = useState(1)


    const formik = useFormik({
      initialValues: {
        name: "",
        wishes: "",
   
      },
      onSubmit: async (values, { setSubmitting }) => {

      },
    });
    
  const {
    errors,
    touched,
    values,
    setFieldValue,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    resetForm,
  } = formik;
  // Load and modify the image with text replacement
  const birthdayQuotes = [
    "The more you praise and celebrate your life, the more there is in life to celebrate.",
    "A birthday is just the first day of another 365-day journey around the sun. Enjoy the trip!",
    "Age is merely the number of years the world has been enjoying you. Cheers to you and your impact on the world.",
    "The best way to remember your wife’s birthday is to forget it once.",
    "Count your life by smiles, not tears. Count your age by friends, not years.",
    "You don’t get older, you get better.",
    "Life is too important to be taken seriously. So, on your birthday, have some fun!",
    "May your birthday be the start of a year filled with good luck, good health, and much happiness.",
    "The older you get, the better you get, unless you’re a banana.",
    "Birthdays are nature’s way of telling us to eat more cake!"
  ];
  
  
  useEffect(() => {
    const loadImage = async () => {
        const data = await getposter();

        if (data && data.signedUrl) {
            const img = new Image();
            img.src = data.signedUrl;
            img.crossOrigin = "anonymous"; // Prevent canvas from being tainted

          img.onload = () => {
            const canvas = canvasRef.current;
            const ctx = canvas.getContext("2d");
            canvas.width = img.width;
            canvas.height = img.height;

            // Draw the image on the canvas
            ctx.drawImage(img, 0, 0);

            // Draw the new text on the image
            const imgData = data.image_data ? JSON.parse(data.image_data) : {};

            if (imgData && imgData?.isImg) {
              setImg(true)
                setQuote(imgData?.isquote)                
          
            }
            ctx.font = imgData.font;
            ctx.fillStyle = imgData.fillStyle; // Set your desired text color
            console.log(JSON.parse(sessionStorage.getItem('user_details')), "JSON.parse(sessionStorage.getItem('user_details'))")
            let user = JSON.parse(sessionStorage.getItem('user_details'))
            ctx.fillText(user.first_name + ' ' + user.last_name || imgData.defaultText,  imgData.x,  imgData.y); 

            const designationText = `${user.designation} | ${user.phone}`; 
            ctx.font =  imgData.font2;
            ctx.fillText(designationText, imgData.x2, imgData.y2);
            
            ctx.font = imgData.fontname
            ctx.fillStyle = imgData.fillStylename;
            ctx.textAlign = 'center';
            ctx.fillText(values.name, canvas.width / 2 - imgData.x3, canvas.height / 2 - imgData.y3);
            

            ctx.font = imgData.fontqut;
            ctx.fillStyle = imgData.fillStylequte;

            const message = values.wishes;
            const maxWidth = canvas.width - 150;
            let lines = [];
            let currentLine = '';
            
            message.split(' ').forEach((word) => {
              const testLine = currentLine ? `${currentLine} ${word}` : word;
              const testWidth = ctx.measureText(testLine).width;
              if (testWidth > maxWidth) {
                lines.push(currentLine); 
                currentLine = word;
              } else {
                currentLine = testLine; 
              }
            });
            lines.push(currentLine); 
            
            const lineHeight = imgData.lineHeightx4;
            let yPosition = canvas.height / 2 +  imgData.y4 
            
            lines.forEach((line, index) => {
              ctx.fillText(line, canvas.width / 2-  imgData.x4  , yPosition + (index * lineHeight));
            });

        


            // const ctx1 = canvas.getContext("2d");
            // canvas.width = img.width;
            // canvas.height = img.height;
            // ctx.drawImage(img, 0, 0);
            // ctx1.font = imgData.font;
            // ctx1.fillStyle = imgData.fillStyle;
            // console.log(JSON.parse(sessionStorage.getItem('user_details')), "JSON.parse(sessionStorage.getItem('user_details'))")
            // ctx1.fillText("AGENT ", 33, 700); 
          };
        }
    };

    if (id) {
        loadImage();
    }
}, [id, newText,values.wishes,values.name,isImg,isquote]); 
  // Handle download
  const handleDownload = () => {
    const canvas = canvasRef.current;
    canvas.toBlob((blob) => {
      let user = JSON.parse(sessionStorage.getItem('user_details'))
      saveAs(blob, `${user.first_name + ' ' + user.last_name+' '+ new Date()
      }.png`); // Save the edited image
    });
  };

  return (
    <div>
   

      {/* <Grid item xs={4} spacing={2} padding={2}>
        <Grid>
        <h2>Custom Image</h2>
        </Grid>
        <Grid>
        <Button onClick={handleDownload}  variant='contained' style={{ marginTop: "10px" }} >
        Download Image
      </Button>
           </Grid>
              </Grid> */}
          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} p={2} >
        <h2>Image</h2>

        <Button onClick={handleDownload}  variant='contained' style={{ marginTop: "10px" }} >
        Download Image
      </Button>
      </Stack>
      { isImg?

      <FormikProvider value={formik}>
  <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
    <Grid container spacing={2} sx={{ mt: 0 }}>
      <Grid item xs={4}>
        <TextField
          fullWidth
          label="Person Name"
          {...getFieldProps("name")}
          error={Boolean(touched.name && errors.name)}
          helperText={touched.name && errors.name}
        />
      </Grid>

              <Grid item xs={4}>
              
                {console.log(isquote,"fojpihf99r")}
                
                {isImg && isquote != 0 ?
                  <FormControl fullWidth error={Boolean(touched.wishes && errors.wishes)}>
                    <InputLabel id="wishes">Wishes Quotation</InputLabel>
                    <Select
                      id="active"
                      {...getFieldProps("wishes")}
                      label="Wishes Quotation"
                      onChange={(e) => setFieldValue("wishes", e.target.value)}
                      value={values.wishes}
                    >
                      {imagedata && imagedata.options && JSON.parse(imagedata.options).map((quote) => (<MenuItem value={quote}>{quote}</MenuItem>))}
                    </Select>
                    <FormHelperText>{touched.wishes && errors.wishes}</FormHelperText>
                  </FormControl> : null}
      </Grid>
    </Grid>
  </Form>
        </FormikProvider>
        : null}
      {/* Canvas to display the image */}
      <canvas ref={canvasRef} style={{ marginTop: "10px" ,width:'100%'}}></canvas>
      {/* Button to download the edited image */}
    </div>
  );
};

export default ImageTextReplacer;
