import { Container, Stack, Typography, Button, Card, TableContainer, Table, TableBody, TableRow, TableCell, IconButton, TablePagination } from '@mui/material';
import React, { useEffect, useState, useRef, useCallback } from 'react';
import Scrollbar from '../../../layouts/Scrollbar';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../../../layouts/user';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import MiscSkeleton from '../../../layouts/user/MiscSkeleton';
import { GetAll, apiURL } from '../../../utils/apiCalls';
import { filter } from 'lodash';
import EditIcon from '@mui/icons-material/Edit';
import SearchNotFound from '../../../layouts/SearchNotFound';
import Iconify from '../../../layouts/Iconify';
import { AgGridReact } from "ag-grid-react";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'emp_id', label: 'Emp. Id', align: 'left' },
  { id: 'first_name', label: 'Name', align: 'left' },
  { id: 'email', label: 'Email', align: 'left' },
  { id: 'phone', label: 'Phone Number', align: 'left' },
  { id: '', label: '', align: 'center' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => JSON.stringify(_user).toLowerCase().trim().indexOf(query.toLowerCase().trim()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

const AdminList = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState();
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);

  async function GetList() {
    setLoading(true);
    const response = await GetAll(apiURL + '/api/v1/users/admin');
    if (response) {
      if (response.data.status) {
        setLoading(false);
        setList(response.data.data)
      }
      else {
        setLoading(false);
        toast.error(response.data.message)
        setList([])
      }
    }
    else {
      setLoading(false);
    }
  };

  useEffect(() => {
    GetList();
  }, [])

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = list.map((n) => n.exporter_company_Name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - list.length) : 0;

  const filteredUsers = applySortFilter(list, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers.length === 0;

  const handleClickEdit = (e) => {
    e.preventDefault();
  };

  const EditButton = (props) => {
    const handleClickEdit = (e) => {
      e.preventDefault();
      navigate(`/dashboard/adminUserRole/${props.data['id']}`)
    };
    return (
      <IconButton color="primary" onClick={(e) => handleClickEdit(e)}> <EditIcon /> </IconButton>
    )
  }

  // define columns and their respective fields
  const columnDefs = [
    {
      field: "emp_id",
      filter: "agTextColumnFilter",
      headerName: "Employee ID",
      cellStyle: { fontSize: '16px' },
      filterParams: {
        filterOptions: ["contains", "notContains", "startsWith", "endsWith"],
        suppressAndOrCondition: true
      }
    },
    {
      field: "first_name",
      filter: "agTextColumnFilter",
      headerName: "Name",
      cellStyle: { fontSize: '16px' },
      filterParams: {
        filterOptions: ["contains", "notContains", "startsWith", "endsWith"],
        suppressAndOrCondition: true
      }
    },
    {
      field: "role_designation",
      filter: "agTextColumnFilter",
      headerName: "Role",
      cellStyle: { fontSize: '16px' },
      filterParams: {
        filterOptions: ["contains", "notContains", "startsWith", "endsWith"],
        suppressAndOrCondition: true
      }
    },
    { field: "email", headerName: "Email", cellStyle: { fontSize: '16px' } },
    { field: "phone", headerName: "Phone", cellStyle: { fontSize: '16px' } },
    { field: "active", headerName: "IsActive", cellStyle: { fontSize: '16px' } },

    { field: 'edit', minWidth: 190, cellRenderer: EditButton }
  ];

  // column properties
  const autoGroupColumnDef = {
    minWidth: 200
  };

  // addded sort and filtering feature
  const defaultColDef = {
    flex: 1,
    sortable: true,
    filter: true
  };

  //  handle tree
  const getDataPath = ({ id, ref_agent_id }) => {
    const result = [id];
    return result;
  };

  const gridRef = useRef();

  // handle search input
  const handleFilter = useCallback((event) => {
    setFilterName(event.target.value);
  }, []);


  return (
    <Container sx={{ m: 0, width: '100%', maxWidth: '100%' }} maxWidth={false}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          Admin
        </Typography>
      </Stack>

      <Card>
        <div className="ag-theme-alpine" style={{ height: "500px", width: "100%" }}>
          {/* search field for filter component */}
          {/* <input
        type="text"
        id="filter-text-box"
        placeholder="Filter list"
        onInput={handleFilter}
      /> */}
          <Stack spacing={2} justifyContent={'space-between'} direction={'row'} alignItems={'center'}>
            <UserListToolbar placeholder="Search Admins..." numSelected={selected.length} filterName={filterName} onFilterName={handleFilter} />
            <Button variant='contained' onClick={() => navigate('/dashboard/adminUserRole/create')} sx={{ height: 'max-content', marginRight: '24px !important' }}
              startIcon={<Iconify icon="subway:add" />}>Create Admin/PROCUREMENT</Button>
          </Stack>

          {/* ag-grid component */}
          {/* <AgGridReact
  rowData={list}
  treeData={false}                 // <-- Remove tree data
  animateRows={true}
  columnDefs={columnDefs}
  defaultColDef={defaultColDef}
  pagination={true}
  paginationPageSize={2}
  style={{ fontSize: '16px' }}
  frameworkComponents={{ EditButton }}
          /> */}
          

                <AgGridReact
                  rowData={filteredUsers}
                  treeData={false}                 // <-- Remove tree data
                  animateRows={true}
                  columnDefs={columnDefs}
                  defaultColDef={defaultColDef}
                  getDataPath={getDataPath}
                  ref={gridRef}
                  pagination={false}
                  // paginationPageSize={2}
                  style={{fontSize:'16px'}}
                  frameworkComponents={{
                    EditButton,
                  }}
                ></AgGridReact>

        </div>
      </Card>
    </Container>
  )
}

export default AdminList;