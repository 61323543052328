import { Container, Stack, Typography, Button, Card, TableContainer, Table, TableBody, TableRow, useMediaQuery, TableCell, Grid, IconButton, TablePagination } from '@mui/material';
import React, { useEffect, useState, useRef, useCallback } from 'react';
import Scrollbar from '../../../layouts/Scrollbar';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../../../layouts/user';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import MiscSkeleton from '../../../layouts/user/MiscSkeleton';
import { GetAll, apiURL } from '../../../utils/apiCalls';
import { filter } from 'lodash';
import EditIcon from '@mui/icons-material/Edit';
import SearchNotFound from '../../../layouts/SearchNotFound';
import Iconify from '../../../layouts/Iconify';
import { dotCase, sentenceCase, capitalCase } from 'change-case';

import { AgGridReact } from "ag-grid-react";
import { fontSize } from '@mui/system';


// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'emp_id', label: 'Emp. Id', align: 'left' },
  { id: 'first_name', label: 'Name', align: 'left' },
  { id: 'email', label: 'Email', align: 'left' },
  { id: 'phone', label: 'Phone Number', align: 'left' },
  { id: '', label: '', align: 'center' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => JSON.stringify(_user).toLowerCase().trim().indexOf(query.toLowerCase().trim()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

const AdminAgentList = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width:600px)');
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState();
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);

  async function GetList() {
    setLoading(true);
    const response = await GetAll(apiURL + '/api/v1/users/agent');
    if (response) {
      if (response.data.status) {
        setLoading(false);
        setList(response.data.data)
      }
      else {
        setLoading(false);
        toast.error(response.data.message)
        setList([])
      }
    }
    else {
      setLoading(false);
    }
  };

  useEffect(() => {
    GetList();
  }, [])

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = list.map((n) => n.exporter_company_Name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - list.length) : 0;

  const filteredUsers = applySortFilter(list, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers.length === 0;




  // const [rowData, setRowData] = useState([
  //   { id: 1, name: "John Doe", parentId: null },
  //   { id: 2, name: "Jane Doe", parentId: 1 },
  //   { id: 3, name: "Micheal Doe", parentId: 1 },
  //   { id: 4, name: "Eobard Thawne", parentId: null },
  //   { id: 5, name: "Bart Thawne", parentId: 4 },
  //   { id: 6, name: "Nora Thawne", parentId: 4 },
  //   { id: 7, name: "Barry Thawne", parentId: 4 },
  //   { id: 8, name: "Ragnarok", parentId: 2 },
  //   { id: 9, name: "SonicQ", parentId: null },
  //   { id: 10, name: "Zoom", parentId: 9 }
  // ]);

  const EditButton = (props) => {
    const handleClickEdit = (e) => {
      e.preventDefault();
      navigate(`/dashboard/adminAgentUser/${props.data['id']}`)
    };
    return (
      <IconButton color="primary" onClick={(e) => handleClickEdit(e)}> <EditIcon /> </IconButton>
    )
  }

  // define columns and their respective fields
  const columnDefs = [
    // {
    //   field: "emp_id",
    //   filter: "agTextColumnFilter",
    //   headerName: "Employee ID",
    //   cellStyle: {fontSize: '16px'},
    //   filterParams: {
    //     filterOptions: ["contains", "notContains", "startsWith", "endsWith"],
    //     suppressAndOrCondition: true
    //   }
    // },
    {
      field: "first_name",
      filter: "agTextColumnFilter",
      headerName: "Name",
      cellStyle: { fontSize: '16px' },
      minWidth: 190,
      filterParams: {
        filterOptions: ["contains", "notContains", "startsWith", "endsWith"],
        suppressAndOrCondition: true
      }
    },
    {
      field: "role.designation",
      filter: "agTextColumnFilter",
      headerName: "Role",
      minWidth: 190,
      cellStyle: { fontSize: '16px' },
      filterParams: {
        filterOptions: ["contains", "notContains", "startsWith", "endsWith"],
        suppressAndOrCondition: true
      }
    },
    { field: "email", headerName: "Email", minWidth: 190, cellStyle: { fontSize: '16px' } },
    { field: "phone", headerName: "Phone Number", minWidth: 190, cellStyle: { fontSize: '16px' } },
    { field: 'edit', minWidth: 190, cellRenderer: EditButton }
  ];

  // column properties
  const autoGroupColumnDef = {
    headerName: "Associate Code",
    minWidth: 200
  };

  // addded sort and filtering feature
  const defaultColDef = {
    flex: 1,
    sortable: true,
    filter: true
  };

  //  handle tree
  const getDataPath = ({ id, ref_agent_id, emp_id }) => {
    let result = [emp_id];
    let row = filteredUsers.find((rr) => rr.id === ref_agent_id);
    // console.log(row)
    while (row) {
      result.unshift(row.emp_id);
      row = filteredUsers.find((r) => r.id === row.ref_agent_id);
    }

    return result;
  };

  const gridRef = useRef();

  // handle search input
  const handleFilter = useCallback((event) => {
    setFilterName(event.target.value);
    gridRef.current?.api.setQuickFilter(event.target.value);
  }, []);


  return (
    <>
      <Container sx={{ m: 0, width: '100%', maxWidth: '100%' }} maxWidth={false}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            AGENTS
          </Typography>
        </Stack>

        {/* <Card>
          <Stack spacing={2} justifyContent={'space-between'} direction={'row'} alignItems={'center'}>
          <UserListToolbar placeholder= "Search Agents..." numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />
         <Button variant='contained' onClick={()=> navigate('/dashboard/agentUser/create')} sx={{height: 'max-content', marginRight: '24px'}} 
         startIcon={<Iconify icon="subway:add" />}>Create Agent</Button>
          </Stack>
         
            <TableContainer sx={{ minWidth: 800 }}>
              <Table stickyHeader >
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={list.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                  isCheckbox={false}
                />
                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                    const { id, emp_id, first_name, last_name, email, phone } = row;
                    return (
                      <TableRow hover key={id}>
                        <TableCell component="th" scope="row" align="left">{emp_id}</TableCell>
                        <TableCell align="left">{`${capitalCase(first_name)} ${capitalCase(last_name)}`}</TableCell>
                        <TableCell align="left">{email}</TableCell>
                        <TableCell align="left">{phone}</TableCell>                      
                        <TableCell align="center">
                       <IconButton color="primary" onClick={(e) => handleClickEdit(e)}> <EditIcon /> </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isUserNotFound && (
                  <TableBody> {loading ? <MiscSkeleton record={{number: 7}}/> :
                    <TableRow>
                      <TableCell align="center" colSpan={7} sx={{ py: 3 }}>
                       {list.length <=0 ? <Typography gutterBottom align="center" variant="subtitle1"> Not found</Typography> :<SearchNotFound searchQuery={filterName} /> }
                      </TableCell>
                    </TableRow> }
                  </TableBody> 
                )}
              </Table>
            </TableContainer>

          <TablePagination
            rowsPerPageOptions={[25, 50, 100]}
            component="div"
            count={filteredUsers.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card> */}

        <Card sx={{ height: '600px' }}>
          <div className="ag-theme-alpine" style={{ height: "500px", width: "100%" }}>
            {/* search field for filter component */}
            {/* <input
        type="text"
        id="filter-text-box"
        placeholder="Filter list"
        onInput={handleFilter}
      /> */}
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} lg={6} md={6} xl={6}>
                <UserListToolbar placeholder="Search Associate..." numSelected={selected.length} filterName={filterName} onFilterName={handleFilter} />
              </Grid>
              <Grid item xs={12} sm={6} lg={6} md={6} xl={6} sx={{ justifyContent: isMobile ? 'center' : 'flex-end', alignItems: 'center', display: 'flex' }}>
                <Button variant='contained' onClick={() => navigate('/dashboard/adminAgentUser/create')} sx={{ height: 'max-content', marginRight: '24px !important', marginBottom: '15px !important', width: isMobile ? '80%' : 'max-content' }}
                  startIcon={<Iconify icon="subway:add" />}>Create ASSOCIATE</Button>
              </Grid></Grid>
            {/* ag-grid component */}
            <AgGridReact
              rowData={filteredUsers}
              treeData={true}
              animateRows={true}
              autoGroupColumnDef={autoGroupColumnDef}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              getDataPath={getDataPath}
              ref={gridRef}
              pagination={true}
              paginationPageSize={100}
              style={{ fontSize: '16px' }}
              frameworkComponents={{
                EditButton,
              }}
            ></AgGridReact>
          </div>
        </Card>
      </Container>
    </>
  )
}

export default AdminAgentList;